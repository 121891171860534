import React from 'react'
import * as FullStory from '@fullstory/browser'
import '@kenect-ut/kenect-ui-kit/lib/styles.css'
import axios from 'axios'
import 'bootstrap/dist/css/bootstrap.css'
import { render } from 'react-dom'
import { localConfig } from './config/localConfig'
import App from './containers/app'
import globalConfig from './globalConfig'
import './styles/index.scss'
import { getPageTitleFromHostname } from './utils/dataUtils'

import './i18n/config'

const target = document.querySelector('#root')
const titleElement = document.querySelector('title')

if (titleElement) {
  titleElement.innerHTML =
    getPageTitleFromHostname(document.location.hostname) || 'Kenect'
}

FullStory.init({
  orgId: 'o-1D8ZE9-na1',
  devMode:
    process.env.REACT_APP_KENECT_ENV === 'develop' ||
    process.env.REACT_APP_KENECT_ENV === 'stage' ||
    window.location.search !== '',
})

const renderApp = () =>
  render(
    <div className="force-100-height">
      <App />
    </div>,
    target
  )

if (window.location.port === '3001') {
  globalConfig.setConfig(localConfig)
  renderApp()
} else {
  axios
    .get('/config')
    .then((config) => {
      globalConfig.setConfig(config.data)
    })
    .catch((err) => console.error(err))
    .finally(() => {
      renderApp()
    })
}

import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber'

const phoneUtil = PhoneNumberUtil.getInstance()

export const getNestedKey = (object, ...keys) => {
  let value = object
  try {
    keys.forEach((key) => {
      value = value[key]
    })
    return value
  } catch (e) {
    return null
  }
}

export const getPageTitleFromHostname = (hostname) => {
  let title = ''
  if (hostname) {
    const elements = hostname.split('.')
    if (elements[0]) {
      title = elements[0].charAt(0).toUpperCase() + elements[0].slice(1)
      if (title === 'Pay') {
        title = 'Payments'
      }
    }
    return title
  }
  return undefined
}

export const getAccessCodeAndRemoveFromURL = (pageInfo) => {
  const searchQuery = pageInfo.search
  const pathName = pageInfo.pathname
  const regex = /(access-code=)\w+/g
  const accessCode = (getNestedKey(searchQuery.match(regex), 0) || '').split(
    '='
  )[1]

  window.history.pushState({}, 'Receipt', pathName)

  return accessCode
}

export const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return ''

  const number = phoneUtil.parse(phoneNumber, 'US')
  return phoneUtil.format(number, PhoneNumberFormat.NATIONAL)
}

export const formatContactName = (contact, phoneNumber) => {
  let name = '-'
  const first = getNestedKey(contact, 'firstName')
  const last = getNestedKey(contact, 'lastName')
  name =
    first || last
      ? `${first ? `${first} ` : ''}${last || ''}`
      : formatPhoneNumber(phoneNumber)
  return name
}

import * as i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import resources from 'src/types/resources'

/**
 * LanguageDetector: https://github.com/i18next/i18next-browser-languageDetector
 * init Configuration: https://www.i18next.com/overview/configuration-options
 */
i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    debug: true,
    resources,
  })
  .catch((err) => console.error(err))

import Loadable from 'react-loadable'
import PoweredByKenectScreen from '../../components/PoweredByKenect'

export const LoadableEmailSubscription = Loadable({
  loader: () => import('../public/emailSubscription/EmailSubscription'),
  loading: PoweredByKenectScreen,
})

export const LoadableFeedback = Loadable({
  loader: () => import('../public/reviews/public'),
  loading: PoweredByKenectScreen,
})

export const LoadableFeedbackV2 = Loadable({
  loader: () => import('../public/reviewsV2/Feedback'),
  loading: PoweredByKenectScreen,
})

export const LoadableMaintenanceMode = Loadable({
  loader: () => import('../public/reviews/Maintenance'),
  loading: PoweredByKenectScreen,
})

export const loadablePayment = Loadable({
  loader: () => import('../public/payments/payments'),
  loading: PoweredByKenectScreen,
})

export const loadablePaymentReceipt = Loadable({
  loader: () => import('../public/payments/receipts/ReceiptWrapper'),
  loading: PoweredByKenectScreen,
})

export const LoadableLandingPage = Loadable({
  loader: () => import('../public/reviews/LandingPage'),
  loading: PoweredByKenectScreen,
})
